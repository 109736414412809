import { Link, toUnit } from 'fractals'
import { Layout } from 'ui'
// TODO: fix this import
// eslint-disable-next-line
import { PhoneTitle, PhoneNumber } from '../..'
import { TPhoneWithTitleProps } from '../../../types'

const PhoneWithTitle = ({ phoneNumbers }: TPhoneWithTitleProps) => (
  <>
    {phoneNumbers.map((phoneNumber, idx) => (
      <Layout.Flex
        key={`PhoneWithTitle__${phoneNumber.phoneNumber}--${idx + 1}`}
        cols='auto'>
        <Link
          styles={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}
          variant='alpha'
          href={`tel:${phoneNumber.phoneNumber}`}>
          <>
            {phoneNumber?.label && <PhoneTitle>{phoneNumber.label}</PhoneTitle>}
            <PhoneNumber>{phoneNumber.phoneNumber}</PhoneNumber>
          </>
        </Link>
      </Layout.Flex>
    ))}
  </>
)

export default PhoneWithTitle
