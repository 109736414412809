import {
  useTranslations,
  Footer as FooterContainer,
  toUnit,
  Link,
  Span,
  Logo,
  TRANSLATIONS,
  PATHS,
  imgLicence,
  Image,
  Div,
  LOCALES_ARR,
  sortBasedOnKey,
  LOCALES,
  useMedia,
  P
} from 'fractals'
import { Layout, NON_BRAKING_SPACE } from 'ui'
import { useRouter } from 'next/router'
import {
  PROGRAMS,
  FACULTIES,
  ABOUT,
  CONTACT,
  FORM_TITLE,
  POLICY,
  TERMS,
  IMG_LICENCE_ALT,
  TFooterProps,
  IMG_LICENCE_NUMBER,
  IconGlobe,
  Subscriber
} from './fractals'
import { RatingEddu } from '../RatingEddu'
import { RatingInformer } from '../RatingInformer'
import { RatingKurshub } from '../RatingKurshub'

const Footer = ({ footer }: TFooterProps) => {
  const [
    programs,
    faculties,
    about,
    contact,
    formTitle,
    policy,
    terms,
    imgLicenceAlt,
    address,
    phoneNumberAlpha,
    phoneNumberBeta,
    freeCallRu,
    freeCallMsc,
    companyName
  ] = useTranslations(
    PROGRAMS,
    FACULTIES,
    ABOUT,
    CONTACT,
    FORM_TITLE,
    POLICY,
    TERMS,
    IMG_LICENCE_ALT,
    TRANSLATIONS.address,
    TRANSLATIONS.phoneNumberAlpha,
    TRANSLATIONS.phoneNumberBeta,
    TRANSLATIONS.freeCallRu,
    TRANSLATIONS.freeCallMsc,
    TRANSLATIONS.companyName
  )

  const { isTablet, isLaptop, isDesktop } = useMedia()

  const { locale } = useRouter()

  const now = new Date()
  const currentYear = now.getFullYear()

  const urls = [
    {
      name: programs,
      items:
        footer?.categories
          ?.map(category => ({
            ...category,
            slug: (category.slug && `/${category.slug}`) || null
          }))
          ?.filter(category => category?.slug) || null
    },
    {
      name: faculties,
      items:
        footer?.faculties
          ?.map(faculty => ({
            ...faculty,
            slug: `${
              PATHS.front.general.programs[
                (locale as keyof typeof LOCALES) || 'ru'
              ]
            }${
              faculty?.slug
                ? `--${
                    PATHS.front.general.faculty[
                      (locale as keyof typeof LOCALES) || 'ru'
                    ]
                  }--${faculty?.slug}`
                : ''
            }`
          }))
          ?.filter(faculty => faculty?.slug) || null
    },
    {
      name: about,
      items: sortBasedOnKey(
        [
          {
            name: contact,
            slug: PATHS.front.contact.index,
            rank: 1
          }
        ],
        'rank'
      )
    }
  ].filter(item => item.items?.length !== 0)

  return (
    <FooterContainer>
      <Layout.Flex
        cols={1}
        styles={{
          marginBottom: toUnit((isDesktop && 71) || (isLaptop && 61) || 40)
        }}>
        <Layout.FlexItem>
          <Logo colorVariant='alpha' id='Footer' />
        </Layout.FlexItem>
      </Layout.Flex>
      <Layout.Flex
        cols={(isDesktop && 2) || 1}
        gap={toUnit((isDesktop && 146) || 56)}>
        {/* LEFT */}
        <Layout.FlexItem>
          <Layout.Flex
            cols={((isDesktop || isLaptop || isTablet) && 3) || 2}
            gap={toUnit(28)}
            styles={{
              justifyContent: 'space-between'
            }}>
            {urls.map((url, idx) => (
              <Layout.FlexItem key={`Footer__url-${url.name}--${idx + 1}`}>
                <P variant='zetaAsH2'>{url.name}</P>

                <Div
                  styles={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: toUnit(24)
                  }}>
                  {url.items?.map((item, idx2) => (
                    <Link
                      variant='alpha'
                      key={`Footer__urls__url-${item.name}--${idx2 + 1}`}
                      href={`${
                        item.slug ||
                        PATHS.front.general.programs[
                          (locale as keyof typeof LOCALES) || 'ru'
                        ]
                      }`}
                      styles={{
                        fontSize: toUnit(16),
                        fontWeight: 400,
                        lineHeight: '120%',
                        color: 'var(--psi)'
                      }}>
                      {item.name}
                    </Link>
                  ))}
                </Div>
              </Layout.FlexItem>
            ))}
          </Layout.Flex>
        </Layout.FlexItem>
        {/* RIGHT */}
        <Layout.FlexItem
          styles={{
            display: 'flex',
            flexDirection: 'column',
            gap: toUnit(65)
          }}>
          <Layout.Flex
            cols={((isLaptop || isTablet) && 2) || 1}
            gap={toUnit(24)}>
            <Layout.FlexItem>
              {/* LICENCE */}
              <Div
                styles={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: toUnit(32)
                }}>
                <Span
                  styles={{
                    fontSize: toUnit(20),
                    fontWeight: 500,
                    lineHeight: '120%'
                  }}>
                  {imgLicenceAlt}
                </Span>
                <Div
                  styles={{
                    display: 'flex',
                    gap: toUnit(24),
                    fontSize: toUnit(14),
                    fontWeight: 500,
                    lineHeight: '120%'
                  }}>
                  <Image
                    bgVariant='transparent'
                    colorVariant='white'
                    borderRadiusVariant='beta'
                    src={imgLicence}
                    width={144}
                    alt={imgLicenceAlt}
                  />
                  {IMG_LICENCE_NUMBER}
                </Div>
              </Div>
            </Layout.FlexItem>
            <Layout.FlexItem>
              <Layout.Flex cols={1} gap={toUnit(24)}>
                {/* adress and phones and languages */}
                <Div
                  styles={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: toUnit(24)
                  }}>
                  {/* ADDRESS */}
                  <Span
                    styles={{
                      fontSize: toUnit(16),
                      fontWeight: 500,
                      lineHeight: '120%'
                    }}>
                    {address}
                  </Span>
                  {/* PHONE NUMBERS */}
                  <Div
                    styles={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: toUnit(24)
                    }}>
                    <Link
                      variant='alpha'
                      href={`tel:${freeCallRu}`}
                      styles={{
                        display: 'flex',
                        gap: toUnit(8),
                        flexDirection: 'column',
                        fontSize: toUnit(14),
                        fontWeight: 500,
                        lineHeight: '120%'
                      }}>
                      <>
                        {freeCallRu && (
                          <Span
                            styles={{
                              fontSize: toUnit(10),
                              fontWeight: 500,
                              lineHeight: '120%',
                              color: 'var(--upsilon)'
                            }}>
                            {freeCallRu}
                          </Span>
                        )}
                        <Span>{phoneNumberAlpha}</Span>
                      </>
                    </Link>
                    <Link
                      variant='alpha'
                      href={`tel:${freeCallMsc}`}
                      styles={{
                        display: 'flex',
                        gap: toUnit(8),
                        flexDirection: 'column',
                        fontSize: toUnit(14),
                        fontWeight: 500,
                        lineHeight: '120%'
                      }}>
                      <>
                        {freeCallMsc && (
                          <Span
                            styles={{
                              fontSize: toUnit(10),
                              fontWeight: 500,
                              lineHeight: '120%',
                              color: 'var(--upsilon)'
                            }}>
                            {freeCallMsc}
                          </Span>
                        )}
                        <Span>{phoneNumberBeta}</Span>
                      </>
                    </Link>
                  </Div>
                  {/* LANGUAGES */}
                  <Div
                    styles={{
                      display: 'flex',
                      gap: toUnit(28)
                    }}>
                    {LOCALES_ARR.map((item, idx) => (
                      <Div key={`Footer__locales--${locale}-${idx + 1}`}>
                        <Link
                          variant='alpha'
                          href={PATHS.front.home.index}
                          locale={item}>
                          <Span
                            styles={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: toUnit(8),
                              fontSize: toUnit(14),
                              fontWeight: 400,
                              lineHeight: '120%',
                              color:
                                (item === locale && 'var(--beta)') ||
                                'var(--alpha)'
                            }}>
                            <IconGlobe
                              colorsVariant={
                                (item === locale && 'beta') || 'alpha'
                              }
                            />
                            {item}
                          </Span>
                        </Link>
                      </Div>
                    ))}
                  </Div>
                </Div>
                <Layout.Flex cols={(isDesktop && 3) || 2} gap={toUnit(16)}>
                  <RatingInformer />
                  <RatingKurshub />
                  <RatingEddu />
                </Layout.Flex>
              </Layout.Flex>
            </Layout.FlexItem>
          </Layout.Flex>
        </Layout.FlexItem>
      </Layout.Flex>
      <Subscriber />
      <Layout.Flex
        cols='auto'
        styles={{
          position: 'relative',
          justifyContent: 'center',
          marginTop: toUnit(64),
          paddingTop: toUnit(24),
          fontSize: toUnit(14),
          fontWeight: 400,
          lineHeight: '120%',
          color: 'var(--psi)',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            minWidth: '100%',
            width: '100%',
            maxWidth: '100%',
            minHeight: toUnit(1),
            height: '100%',
            maxHeight: toUnit(1),
            backgroundColor: 'var(--rho)'
          }
        }}>
        <Layout.FlexItem>
          <Span>
            &copy;{NON_BRAKING_SPACE}
            {companyName} {currentYear}
          </Span>
        </Layout.FlexItem>
      </Layout.Flex>
    </FooterContainer>
  )
}

export default Footer
