import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  PATHS,
  LOCALES,
  useMedia,
  multiMedia,
  Div,
  Button
} from 'fractals'
import { Layout, slugify } from 'ui'
import { useRouter } from 'next/router'
import { isDesktop as isDesktopRDP } from 'react-device-detect'
import {
  TFacultiesProps,
  FACULTY_DEFAULT_CATEGORY_TITLE,
  FACULTY_DEFAULT_CATEGORY_DESCRIPTION,
  FACULTY_DEFAULT_FACULTY_NAME,
  Card,
  filterFacultiesData
} from './fractals'
import imgDefault from './management.jpg'
import { styles } from '../styles'

// TODO: refactor cards
const Faculties = ({ faculties }: TFacultiesProps) => {
  const { isLaptop, isDesktop } = useMedia()
  const { locale, push, pathname } = useRouter()

  const FACULTIES_AMOUNT = isDesktopRDP ? 6 : 4

  const [
    facultyDefaultCategoryTitle,
    facultyDefaultCategoryDescription,
    facultyDefaultFacultyName
  ] = useTranslations(
    FACULTY_DEFAULT_CATEGORY_TITLE,
    FACULTY_DEFAULT_CATEGORY_DESCRIPTION,
    FACULTY_DEFAULT_FACULTY_NAME
  )

  if (!faculties || faculties.length === 0) return null

  const filteredFaculties = filterFacultiesData(faculties)

  return (
    <Layout.Flex cols={1}>
      {filteredFaculties?.map((faculty, idx) => (
        <Layout.FlexItem
          key={`Faculties__${faculty.category?.name}-${idx + 1}`}>
          <Section
            id={slugify(faculty.category?.name || facultyDefaultCategoryTitle)}
            spacingVariant='alpha'
            styles={{ paddingTop: 0 }}>
            <Container
              styles={{
                boxSizing: 'border-box'
              }}>
              <Div
                styles={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '40px'
                }}>
                <Layout.FlexItem>
                  <Layout.Flex
                    cols={((isDesktop || isLaptop) && 2) || 1}
                    proportion={((isDesktop || isLaptop) && '1:2') || undefined}
                    gap={toUnit(32)}>
                    <Layout.FlexItem>
                      <H2
                        styles={{
                          marginBottom: 'auto'
                        }}
                        variant='alpha'>
                        {faculty.category?.name || facultyDefaultCategoryTitle}
                      </H2>
                    </Layout.FlexItem>
                    <Layout.FlexItem>
                      <P
                        styles={{
                          color: '#8B8B8B'
                        }}
                        variant='beta'>
                        {faculty.category?.description ||
                          facultyDefaultCategoryDescription}
                      </P>
                    </Layout.FlexItem>
                  </Layout.Flex>
                </Layout.FlexItem>
                <Layout.FlexItem>
                  <Div
                    styles={{
                      minWidth: 0,
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > *': {
                        maxWidth: 'fit-content',
                        ...multiMedia({
                          desktop: { flexBasis: 'calc(100% / 3)' },
                          laptop: { flexBasis: 'calc(100% / 2)' },
                          tablet: { flexBasis: 'calc(100% / 2)' },
                          phone: { minWidth: '-webkit-fill-available' }
                        })
                      },
                      ...multiMedia({
                        desktop: { gap: '32px' },
                        laptop: { gap: '32px' },
                        tablet: { gap: '32px' },
                        phone: { gap: '40px' }
                      })
                    }}>
                    {faculty.faculties &&
                      faculty.faculties?.map((facultyCard, facultyCardIdx) =>
                        facultyCardIdx + 1 <= FACULTIES_AMOUNT ? (
                          <Layout.FlexItem
                            styles={{
                              '& > a': {
                                ...multiMedia({
                                  phone: { width: '100%' }
                                })
                              }
                            }}
                            key={`Faculties__${facultyCard.name}-${
                              facultyCardIdx + 1
                            }`}>
                            <Card
                              nameCource={
                                facultyCard.name || facultyDefaultFacultyName
                              }
                              accentColor={facultyCard.color || 'var(--beta)'}
                              backgroundImage={
                                facultyCard.image?.url || imgDefault
                              }
                              countCources={facultyCard?.programCount ?? 0}
                              route={`${
                                faculty?.category?.slug ||
                                PATHS.front.general.programs[
                                  (locale as keyof typeof LOCALES) || 'ru'
                                ]
                              }${
                                facultyCard?.slug
                                  ? `--${
                                      PATHS.front.general.faculty[
                                        (locale as keyof typeof LOCALES) || 'ru'
                                      ]
                                    }--${facultyCard?.slug}`
                                  : ''
                              }`}
                            />
                          </Layout.FlexItem>
                        ) : null
                      )}
                  </Div>
                </Layout.FlexItem>
              </Div>
              <Button
                onClick={() =>
                  push(`${pathname}${faculty.category?.slug ?? ''}`)
                }
                styles={styles.button}
                colorsVariant='omegaReverse'>
                Все направления
              </Button>
            </Container>
          </Section>
        </Layout.FlexItem>
      ))}
    </Layout.Flex>
  )
}

export default Faculties
