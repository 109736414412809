import { Link, Image } from 'fractals'
import { useEffect, useRef } from 'react'

export const RatingInformer = () => {
  const informerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fetchInformerData = async () => {
      try {
        const response = await fetch('https://kursfinder.ru/informer/8/')
        if (response.ok) {
          const data = await response.json()
          if (informerRef.current) {
            informerRef.current.innerHTML = data.data
          }
        } else {
          throw new Error('RatingInformer: Failed to fetch data')
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('RatingInformer: error:', error)
      }
    }

    fetchInformerData()
  }, [])

  return (
    <div>
      <link
        rel='stylesheet'
        href='https://kursfinder.ru/static/css/informer.css'
      />
      <div ref={informerRef}>
        <Link
          variant='alpha'
          href='https://kursfinder.ru/school/mitu/'
          className='informer informer-140 informer_v1'
          target='_blank'
          rel='noopener noreferrer'
          title='Отзывы о МИТУ на Kursfinder'>
          <Image
            colorVariant='white'
            src='https://kursfinder.ru/static/img/informer/logo_v1.svg'
            className='informer__logo'
            alt='Kursfinder Logo'
            width={30}
            height={30}
          />
        </Link>
      </div>
    </div>
  )
}

export default RatingInformer
