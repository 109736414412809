// eslint-disable-next-line import/no-cycle
import {
  IsUnderDevelopment,
  getProgramImagesData,
  PageContainer,
  Header,
  getHeaderData,
  getSearchProgramsData,
  Footer,
  getFooterData,
  StudySteps,
  getStudyStepsData,
  Video,
  getVideoData,
  Diplomas,
  getDiplomasData,
  WhyUs,
  getWhyUsData,
  WhyUsLegacy,
  getWhyUsDataLegacy,
  FAQ,
  getFAQData,
  LeadForm,
  LeadPopup,
  Breadcrumbs,
  BreadcrumbsReact,
  getBreadcrumbs,
  AskModal,
  RatingEddu,
  Scroll
} from './widgets'
import { getLeastProgramContentData } from './widgets/LeadForm/fractals/api'

const Shared = {
  IsUnderDevelopment,
  PageContainer,
  Header,
  getHeaderData,
  getSearchProgramsData,
  Footer,
  getFooterData,
  StudySteps,
  getStudyStepsData,
  Video,
  getVideoData,
  Diplomas,
  getDiplomasData,
  WhyUs,
  getWhyUsData,
  WhyUsLegacy,
  getWhyUsDataLegacy,
  FAQ,
  getFAQData,
  LeadForm,
  LeadPopup,
  Breadcrumbs,
  BreadcrumbsReact,
  getLeastProgramContentData,
  getProgramImagesData,
  getBreadcrumbs,
  AskModal,
  RatingEddu,
  Scroll
}

export default Shared
