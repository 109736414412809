import React, { useState } from 'react'
import {
  Div,
  Link,
  IconTelegramFooter,
  IconYoutubeFooter,
  IconVKFooter,
  toUnit,
  Input,
  P,
  Form,
  PATHS,
  Button,
  Label,
  multiMedia,
  IconArrow,
  Span
} from 'fractals'
import { getCookies } from 'cookies-next'
import axios from 'axios'
import { margetingLead } from '@/modules/shared/widgets/LeadForm/LeadForm'
import { Spinner } from '@/fractals/components'

export const Subscriber = () => {
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('available')

  type TSubmitProps = {
    email: string
    subscribe: string
  }

  const cookies = getCookies()

  const handleSubmit = async ({ ...values }: TSubmitProps) => {
    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.email)) {
      try {
        setContent('loading')
        const response = await axios.post(`${PATHS.front.api.lead.index}`, {
          ...values
        })
        if (response.status === 200) {
          setContent('thanks')
        } else {
          setContent('error')
        }
      } catch (e) {
        console.log('Error message: ', e)
      }
    } else {
      setContent('alert')
    }
  }

  return (
    <Div
      styles={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <P variant='footerSubscriber'>Подписаться на обновления</P>
      <Div
        styles={{
          display: 'flex',
          ...multiMedia({
            desktop: { gap: '200px', flexDirection: 'row' },
            laptop: {
              justifyContent: 'space-between',
              gap: '200px',
              flexDirection: 'row'
            },
            tablet: { justifyContent: 'space-between', flexDirection: 'row' },
            phone: { gap: '40px', flexDirection: 'column' }
          })
        }}>
        <Form
          styles={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            position: 'relative',
            ...multiMedia({
              desktop: { width: '100%', marginRight: '20px' },
              laptop: { width: '100%', marginRight: '20px' },
              tablet: { width: '100%', marginRight: '20px' }
            })
          }}
          onSubmit={e => {
            e.preventDefault()
            handleSubmit({
              email,
              subscribe: 'Да'
            })
            margetingLead({
              event: 'getmail',
              program: null,
              googleClientId: cookies.gclUid || null,
              email
            })
          }}
          formName='subscribe__form'>
          <Label htmlFor='subscribe__input' childrenVariant='email' />
          <Div
            styles={{
              display: 'flex',
              position: 'relative',
              ...multiMedia({
                desktop: { maxWidth: '488px', width: '100%' },
                laptop: { maxWidth: '488px', width: '100%' },
                tablet: { maxWidth: '416px', width: '100%' },
                phone: { width: '100%' }
              })
            }}>
            <Input
              styles={{
                height: '48px',
                borderRadius: '8px',
                '&::placeholder': {
                  color: '#C4C4C4'
                },
                ...multiMedia({
                  desktop: { maxWidth: '488px', width: '100%' },
                  laptop: { maxWidth: '488px', width: '100%' },
                  tablet: { maxWidth: '416px', width: '100%' },
                  phone: { width: '100%' }
                })
              }}
              onChange={e => setEmail(e.target.value)}
              value={email}
              colorVariant={{ color: 'black' }}
              id='subscribe__input'
              type='email'
              name='subscriber'
              placeholder='email'
            />
            <Button
              disabled={content === 'thanks'}
              styles={{
                position: 'absolute',
                padding: '7px',
                right: '4px',
                top: '4px',
                left: 'auto',
                boxSizing: 'border-box',
                '&:disabled': {
                  backgroundColor: '#ccc',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%'
                },
                ...((content === 'loading' || content === 'thanks') && {
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%'
                })
              }}
              type='submit'>
              {((content === 'available' ||
                content === 'alert' ||
                content === 'error') && (
                <IconArrow
                  styles={{
                    '& > svg > path': {
                      stroke: 'white'
                    }
                  }}
                  direction='upRight'
                />
              )) ||
                (content === 'loading' && <Spinner />)}
            </Button>
          </Div>
          {(content === 'error' ||
            content === 'alert' ||
            content === 'thanks') && (
            <Span
              styles={{
                color:
                  ((content === 'error' || content === 'alert') && 'red') ||
                  'green',
                right: 0,
                position: 'absolute'
              }}>
              {(content === 'error' && 'Произошла ошибка') ||
                (content === 'alert' && 'Введите корректный адрес почты') ||
                (content === 'thanks' && 'Спасибо за подписку!')}
            </Span>
          )}
        </Form>
        <Div
          styles={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '52px',
            transform: 'translateY(25%)'
          }}>
          <Link variant='alpha' href='https://t.me/mitm_institute'>
            <IconTelegramFooter />
          </Link>
          <Link variant='alpha' href='https://vk.com/mitm.institute'>
            <IconVKFooter />
          </Link>
          {/* <IconYoutubeFooter /> */}
        </Div>
      </Div>
    </Div>
  )
}

export default Subscriber
