import {
  Main,
  useAt,
  PATHS,
  useTranslations,
  TRANSLATIONS,
  IconBelowHeaderDecoration,
  toUnit,
  useMedia,
  LOCALES,
  Container,
  multiMedia
} from 'fractals'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { Shared } from 'modules'

import { Opportunities, Banner } from './widgets'
// TODO: remove the next line and fix the error
// eslint-disable-next-line
import { TProgramsPageProps, SEO_TITLE, SEO_DESCRIPTION } from './fractals'
import { BreadcrumbsReact } from '@/modules/shared/widgets'
import { VideoVariants } from '../shared/widgets/Video/fractals/types'
import { Spinner } from '@/fractals/components'
import { VARIANTS_KEYS } from '@/fractals/components/atoms/Header/constants'

const DynamicPrograms = dynamic(() => import('./widgets/Programs/Programs'), {
  loading: () => <Spinner />
})

const ProgramsPage = ({
  images,
  categories,
  faculties,
  programs,
  footer,
  seo,
  questions,
  videos,
  banner
}: TProgramsPageProps) => {
  const { asPath, locale } = useRouter()
  const params = asPath.split('?query')[1]?.split('=')?.[1]?.split('--')

  const [companyName, defaultSeoTitle, defaultSeoDescription] = useTranslations(
    TRANSLATIONS.companyName,
    SEO_TITLE,
    SEO_DESCRIPTION
  )

  const {
    currentCategorySlug,
    currentFacultySlug,
    isBachelor,
    isMaster,
    isCourse,
    isCollege,
    isWeekendGroup
  } = useAt()

  const { isMobile } = useMedia()

  const currentCategory =
    categories?.categories?.find(({ slug }) => slug === currentCategorySlug) ||
    null

  const currentFaculty = faculties?.find(
    ({ slug }) => slug === currentFacultySlug
  )

  const currentFacultyName = currentFaculty?.name || 'Факультет'

  const seoParams = {
    title:
      (currentFacultySlug && seo.faculty?.seo.metaTitle) ||
      (currentFacultySlug &&
        locale === LOCALES.ru &&
        `${
          (isCourse &&
            `${currentFacultyName}: обучение дистанционно | Онлайн курсы от института MITM`) ||
          (isBachelor &&
            `${currentFacultyName}: обучение дистанционно | Высшее образование - онлайн курсы`) ||
          (isCollege &&
            `${currentFacultyName}: колледж (СПО) программы онлайн обучения`) ||
          (isWeekendGroup &&
            `${currentFacultyName}: программы группы выходного дня в Москве`) ||
          (isMaster
            ? `${currentFacultyName}: обучение дистанционно | Магистратура - высшее образование онлайн`
            : `${currentFacultyName}: программы онлайн обучения от института MITM`)
        }`) ||
      (currentCategorySlug && seo.category?.seo.metaTitle) ||
      (currentCategorySlug &&
        locale === LOCALES.ru &&
        `${
          (isCourse &&
            'Онлайн-курсы дополнительного профессионального образования | Обучение от института MITM') ||
          (isMaster &&
            'Магистратура онлайн - Вторая ступень высшего образования от MITM.institute') ||
          (isBachelor &&
            'Высшее образование онлайн (Бакалавриат) с дипломом | Обучение от института MITM') ||
          ''
        }`) ||
      defaultSeoTitle,
    desc: (
      (currentFacultySlug && seo.faculty?.seo.metaDescription) ||
      (currentFacultySlug &&
        locale === LOCALES.ru &&
        `${
          (isCourse &&
            `Программы обучения "${currentFacultyName}" от самого инновационного ВУЗа страны - MITM.institute. Курсы дополнительного профессионального образования (ДПО) вместе с ведущими экспертами-практиками. Дистанционный формат обучения ➜ Скидка - 30% Освой новую профессию с нуля!`) ||
          (isBachelor &&
            `Программы обучения "${currentFacultyName}" от самого инновационного ВУЗа страны - MITM.institute. Высшее образование (бакалавриат) вместе с экспертами-практиками ➜ Дистанционный формат обучения. Диплом государственного образца! Скидка - 30%`) ||
          (isCollege &&
            `Онлайн-колледж от инcтитута МИТМ | "${currentFacultyName}" - дистанционные программы обучения СПО для аббитуриентов после 9 и 11 класса ➜ Диплом о Среднем Профессиональном Образовании государственного образца!`) ||
          (isWeekendGroup &&
            `${currentFacultyName}: программы обучения по выходным (очно-заочная форма) с дипломом о профессиональной переподготовке государственного образца (бакалавриат) от онлайн-института MITM | Высшее образование в удобном формате!`) ||
          (isMaster &&
            `Программы обучения "${currentFacultyName}" от самого инновационного ВУЗа страны - MITM.institute. Высшее образование (магистратура) вместе с экспертами-практиками ➜ Дистанционный формат обучения. Диплом государственного образца! Скидка - 30%`) ||
          `${currentFacultyName} - дистанционное образование от онлайн-института MITM. Удобный формат обучения со скидкой 30% ➜ Освой востребованные специальности вместе с экспертами-практиками! Возможность рассрочки!`
        }`) ||
      (currentCategorySlug && seo.category?.seo.metaDescription) ||
      (currentCategorySlug &&
        locale === LOCALES.ru &&
        `${
          (isCourse &&
            'Дополнительное профессиональное образование (ДПО) по востребованным направлениям и специальностям от самого инновационного ВУЗа страны - MITM.institute ➜ Дистанционное обучение со скидкой - 30% Освой новую профессию с нуля!') ||
          (isMaster &&
            'Самый инновационный ВУЗ страны - MITM.institute предлагает получить Вторую ступень высшего образования (магистратура) с дипломом государственного образца ➜ Скидка - 30% Ведущие эксперты-практики! Удобный дистанционный формат обучения') ||
          (isBachelor &&
            'Самый инновационный ВУЗ страны - MITM.institute предлагает получить Высшее образование (бакалавриат) с дипломом государственного образца ➜ Скидка - 30% Ведущие эксперты-практики! Удобный дистанционный формат обучения.') ||
          ''
        }`) ||
      defaultSeoDescription
    )
      .replace(
        '%CATEGORY%',
        categories?.categories?.find(
          category => category?.slug === currentCategorySlug
        )?.name || ''
      )
      .replace(
        '%CATEGORY_LABEL%',
        categories?.categories?.find(
          category => category?.slug === currentCategorySlug
        )?.label || ''
      ),
    canonical:
      (currentFacultySlug
        ? seo?.faculty?.seo.canonicalURL
        : seo?.category?.seo.canonicalURL) ||
      `${PATHS.front.root.index}${asPath}`
  }

  return (
    <>
      <NextSeo
        title={seoParams.title}
        description={seoParams.desc}
        canonical={seoParams.canonical}
        openGraph={{
          url: seoParams.canonical,
          title: seoParams.title,
          description: seoParams.desc,
          images: [
            {
              url: `${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`,
              width: 512,
              height: 512,
              alt: companyName,
              type: 'image/png'
            }
          ],
          site_name: companyName
        }}
      />
      {locale !== 'ru' && (
        <Head>
          <meta name='robots' content='noindex, nofollow' />
        </Head>
      )}
      <Shared.PageContainer id='program-page'>
        <Shared.Header
          images={images}
          categories={categories}
          containerVariant={VARIANTS_KEYS.beta}
        />
        {!isMobile && (
          <Container styles={{ padding: '0' }}>
            <BreadcrumbsReact bread={null} />
          </Container>
        )}
        <Banner
          // eslint-disable-next-line
          // @ts-ignore
          data={currentFaculty}
          // eslint-disable-next-line
          // @ts-ignore
          category={
            currentCategory
              ? {
                  // eslint-disable-next-line
                  // @ts-ignore
                  id: currentCategory.id,
                  name: currentCategory.name,
                  // eslint-disable-next-line
                  // @ts-ignore
                  description: currentCategory.description
                }
              : null
          }
          defaultData={banner}
        />
        <Main>
          <DynamicPrograms
            params={params}
            categories={categories}
            faculties={faculties}
            programs={programs}
          />
          <Opportunities />
          <Shared.Video
            id='video'
            videos={videos}
            variant={VideoVariants.programs}
          />
          <Shared.FAQ questions={questions} />
          <Shared.Scroll targetId='program-page' />
          {/* <Shared.AskModal /> */}
        </Main>
        <Shared.Footer footer={footer} />
      </Shared.PageContainer>
    </>
  )
}

export default ProgramsPage
