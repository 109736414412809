import { LOCALES } from 'fractals'

export const STYLE_VARIANT_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma'
} as const

export const COLORS_VARIANT_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta'
} as const

export const DEFAULT_FORM_HEADING = {
  [LOCALES.ru]:
    'Оставьте заявку и наши консультанты приемной комиссии расскажут все условия ускоренного обучения',
  [LOCALES.en]:
    'Leave an application and our consultants of the admission committee will tell you all the conditions of accelerated learning',
  [LOCALES.uz]:
    'Murojaatni qoldiring va qabul komissiyasi konsullarimiz sizga tez o’quv shartlarini bular',
  [LOCALES.kk]:
    'Мурожаатты жіберіңіз және кабул комиссиясы консультанттарымыз сізге тез оқу шарттарын білдіретін'
} as const

export const DEFAULT_BUTTON_VALUE = {
  [LOCALES.en]: 'Submit',
  [LOCALES.ru]: 'Отправить заявку',
  [LOCALES.uz]: 'Murojaatni yuborish',
  [LOCALES.kk]: 'Мурожаатты жіберу'
} as const

export const POLICY_AGREEMENT = {
  [LOCALES.en]:
    'By submitting an application, you agree to the privacy policy and terms of processing personal data',
  [LOCALES.ru]:
    'Отправляя заявку, Вы соглашаетесь с политикой конфиденциальности и условиями обработки персональных данных',
  [LOCALES.uz]:
    'Murojaatni yuborish orqali, siz maxfiylik siyosatiga va shaxsiy ma’lumotlarni ishlash shartlariga rozilik bildirgansiz',
  [LOCALES.kk]:
    'Мурожаатты жіберу арқылы, сіз мақфия саясатқа және шахси мәліметтерді жүргізу шарттарына розилик бердіңіз'
} as const

export const IMAGE_ALT = {
  [LOCALES.en]: 'Student is learning online',
  [LOCALES.ru]: 'Студент обучается онлайн',
  [LOCALES.uz]: 'Talaba onlayn o’qiyapti',
  [LOCALES.kk]: 'Студент оқып тұр'
} as const

export const IS_LOADING = {
  [LOCALES.en]: 'Your application is being sent... Please wait',
  [LOCALES.ru]: 'Ваша заявка отправляется... Пожалуйста, подождите',
  [LOCALES.uz]: 'Sizning murojaatingiz yuborilmoqda... Iltimos, kuting',
  [LOCALES.kk]: 'Сіздің мурожаатыңыз жіберілуде... Күте тұрыңыз'
} as const

export const IS_SENT = {
  [LOCALES.en]:
    'Thank you! Your application has been sent. We will contact you soon',
  [LOCALES.ru]:
    'Спасибо! Ваша заявка отправлена. Мы свяжемся с вами в ближайшее время',
  [LOCALES.uz]:
    'Rahmat! Sizning murojaatingiz yuborildi. Tez orada siz bilan bog’lanamiz',
  [LOCALES.kk]:
    'Рахмет! Сіздің мурожаатыңыз жіберілді. Тез орда сіз бізбен байланысамыз'
} as const

export const IS_ERORR = {
  [LOCALES.en]: 'An error occurred. Please try again or contact us by phone',
  [LOCALES.ru]:
    'Произошла ошибка. Пожалуйста, попробуйте еще раз или свяжитесь с нами по телефону',
  [LOCALES.uz]:
    'Xatol ik yuz berdi. Iltimos, qayta urinib ko’ring yoki biz bilan telefon orqali bog’laning',
  [LOCALES.kk]:
    'Қате кетті. Кейінірек қайталаңыз немесе бізбен телефонмен байланысыңыз'
} as const

export const NAME_IS_REQUIRED = {
  [LOCALES.en]: 'Please enter your name',
  [LOCALES.ru]: 'Пожалуйста, введи ваше имя',
  [LOCALES.uz]: 'Iltimos, ismingizni kiriting',
  [LOCALES.kk]: 'Аты-жөніңізді енгізіңіз'
}

export const NAME_MAX_LENGTH_VALUE = 32
export const NAME_MAX_LENTH = {
  [LOCALES.en]: `Please enter a value less than ${NAME_MAX_LENGTH_VALUE} characters`,
  [LOCALES.ru]: `Пожалуйста, введите значение короче, чем ${NAME_MAX_LENGTH_VALUE} символа`,
  [LOCALES.uz]: `Iltimos, ${NAME_MAX_LENGTH_VALUE} ta belgidan kam qiymat kiriting`,
  [LOCALES.kk]: `Кейінірек ${NAME_MAX_LENGTH_VALUE} та таңбадан кем мәнді енгізіңіз`
} as const

export const PHONE_NUMBER_IS_REQUIRED = {
  [LOCALES.en]: 'Please enter your phone number',
  [LOCALES.ru]: 'Пожалуйста, введите ваш номер телефона',
  [LOCALES.uz]: 'Iltimos, telefon raqamingizni kiriting',
  [LOCALES.kk]: 'Телефон нөміріңізді енгізіңіз'
} as const

export const PHONE_NUMBER_MIN_LENGTH_VALUE = 4
export const PHONE_NUMBER_MIN_LENGTH = {
  [LOCALES.en]: `Please enter a value greater than ${PHONE_NUMBER_MIN_LENGTH_VALUE} characters`,
  [LOCALES.ru]: `Пожалуйста, введите значение больше, чем ${PHONE_NUMBER_MIN_LENGTH_VALUE} символа`,
  [LOCALES.uz]: `Iltimos, ${PHONE_NUMBER_MIN_LENGTH_VALUE} ta belgidan ko’proq qiymat kiriting`,
  [LOCALES.kk]: `Кейінірек ${PHONE_NUMBER_MIN_LENGTH_VALUE} та таңбадан асты мәнді енгізіңіз`
} as const

export const PHONE_NUMBER_PATTERN = {
  [LOCALES.en]: `Пожалуйста, введите корректный номер телефона`,
  [LOCALES.ru]: `Пожалуйста, введите корректный номер телефона`,
  [LOCALES.uz]: `Пожалуйста, введите корректный номер телефона`,
  [LOCALES.kk]: `Пожалуйста, введите корректный номер телефона`
} as const

export const EMAIL_PATTERN = {
  [LOCALES.en]: 'Please enter a valid email',
  [LOCALES.ru]: 'Пожалуйста, введите корректный email',
  [LOCALES.uz]: 'Iltimos, to’g’ri email kiriting',
  [LOCALES.kk]: 'Дұрыс электрондық пошта енгізіңіз'
} as const

export const EMAIL_MAX_LENGTH_VALUE = 64
export const PROMOCODE_MAX_LENGTH_VALUE = 32

export const EMAIL_MAX_LENGTH = {
  [LOCALES.en]: `Please enter a value less than ${EMAIL_MAX_LENGTH_VALUE} characters`,
  [LOCALES.ru]: `Пожалуйста, введите значение короче, чем ${EMAIL_MAX_LENGTH_VALUE} символа`,
  [LOCALES.uz]: `Iltimos, ${EMAIL_MAX_LENGTH_VALUE} ta belgidan kam qiymat kiriting`,
  [LOCALES.kk]: `Кейінірек ${EMAIL_MAX_LENGTH_VALUE} та таңбадан кем мәнді енгізіңіз`
} as const

export const PROMOCODE_MAX_LENGTH = {
  [LOCALES.en]: `Please enter a value less than ${PROMOCODE_MAX_LENGTH_VALUE} characters`,
  [LOCALES.ru]: `Пожалуйста, введите значение короче, чем ${PROMOCODE_MAX_LENGTH_VALUE} символа`,
  [LOCALES.uz]: `Iltimos, ${PROMOCODE_MAX_LENGTH_VALUE} ta belgidan kam qiymat kiriting`,
  [LOCALES.kk]: `Кейінірек ${PROMOCODE_MAX_LENGTH_VALUE} та таңбадан кем мәнді енгізіңіз`
} as const
