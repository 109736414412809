export const dictionary: any = {
  'pedagogicheskoe-obrazovanie': 'Педагогическое образование',
  'promyshlennoe-i-grazhdanskoe-stroitelstvo-1':
    'Промышленное и гражданское строительство',
  'informaczionnaya-bezopasnost': 'Информационная безопасность',
  'dizajn-i-prodvizhenie-czifrovogo-produkta':
    'Дизайн и продвижение цифрового продукта',
  'czifrovoj-dizajn': 'Цифровой дизайн',
  'programmnoe-obespechenie-internet-i-oblachnye-tehnologii':
    'Программное обеспечение, Интернет и облачные технологии',
  'brend-strategiya-v-czifrovyh-kommunikacziyah':
    'Бренд-стратегия в цифровых коммуникациях',
  'bankovskij-menedzhment-i-upravlenie-riskami':
    'Банковский менеджмент и управление рисками',
  'menedzhment-v-kibersporte-i-fidzhital-sporte':
    'Менеджмент в киберспорте и фиджитал спорте',
  'menedzhment-v-obrazovanii-1': 'Менеджмент в образовании',
  'strategicheskij-menedzhment-1': 'Стратегический менеджмент',
  'strategicheskoe-upravlenie-i-innovaczionnye-tehnologii-v-industrii-gostepriimstva':
    'Стратегическое управление и инновационные технологии в индустрии гостеприимства',
  'tehnologicheskoe-predprinimatelstvo-1':
    'Технологическое предпринимательство',
  'upravlenie-informaczionnymi-tehnologiyami-v-organizaczii':
    'Управление информационными технологиями в организации',
  'upravlenie-sportivnoj-organizacziej': 'Управление спортивной организацией',
  'upravlenie-czifrovoj-transformacziej-biznesa':
    'Управление цифровой трансформацией бизнеса',
  'hr-menedzhment': 'HR-менеджмент',
  'psihologiya-i-pedagogika-dopolnitelnogo-obrazovaniya-detej':
    'Психология и педагогика дополнительного образования детей',
  'psihologiya-obrazovaniya': 'Психология образования',
  'buhgalterskij-uchet-analiz-i-audit-1': 'Бухгалтерский учет, анализ и аудит',
  'instrumentalnye-metody-i-modeli-v-czifrovoj-ekonomike-1':
    'Инструментальные методы и модели в цифровой экономике',
  'finansy-1': 'Финансы',
  'czifrovaya-ekonomika-i-iskusstvennyj-intellekt':
    'Цифровая экономика и искусственный интеллект',
  'elektronnaya-kommercziya': 'Электронная коммерция',
  'prodyuser-v-oblasti-kinematografii': 'Продюсер в области кинематографии',
  'multimedijnaya-zhurnalistika': 'Мультимедийная журналистика',
  'mezhdunarodnaya-torgovlya': 'Международная торговля',
  'arhitekturnyj-dizajn': 'Архитектурный дизайн',
  'event-menedzhment': 'Event-менеджмент',
  'tehnologicheskoe-predprinimatelstvo': 'Технологическое предпринимательство',
  prodyusirovanie: 'Продюсирование',
  'menedzhment-naukoemkih-proizvodstv-neftegazohimicheskogo-kompleksa':
    'Менеджмент наукоемких производств нефтегазохимического комплекса',
  'menedzhment-igrovoj-industrii-i-kibersporta':
    'Менеджмент игровой индустрии и киберспорта',
  'menedzhment-v-muzykalnoj-industrii': 'Менеджмент в музыкальной индустрии',
  'komyuniti-menedzhment': 'Комьюнити-менеджмент',
  'sozdanie-i-prodvizhenie-mediaproduktov':
    'Создание и продвижение медиапродуктов',
  'brend-menedzhment-v-mediaprostranstve':
    'Бренд-менеджмент в медиапространстве',
  'full-stack-razrabotchik': 'Full-stack разработчик',
  'testirovanie-i-dev-ops': 'Тестирование и DevOps',
  'razrabotka-kompyuternyh-igr-dopolnennoj-i-virtualnoj-realnosti':
    'Разработка компьютерных игр, дополненной и виртуальной реальности',
  'it-menedzhment-v-biznese': 'ИТ-менеджмент в бизнесе',
  'iskusstvennyj-intellekt-i-bolshie-dannye':
    'Искусственный интеллект и большие данные',
  'gejmdizajn-i-razrabotka-igr': 'Геймдизайн и разработка игр',
  'ux-ui-dizajn': 'UX/UI-дизайн',
  'hudozhnik-graficheskih-iskusstv': 'Художник графических искусств',
  'kommunikaczionnyj-dizajn': 'Коммуникационный дизайн',
  'graficheskij-dizajn-i-virtualnaya-dopolnennaya-realnost':
    'Графический дизайн и виртуальная дополненная реальность',
  'ugolovnoe-pravo-2': 'Уголовное право',
  'magistr-ugolovnogo-grazhdanskogo-i-administrativnogo-sudoproizvodstva':
    'Магистр уголовного, гражданского и административного судопроизводства',
  'grazhdanskoe-pravo-2': 'Гражданское право',
  'upravlenie-innovacziyami-v-obrazovanii':
    'Управление инновациями в образовании',
  'ugolovnoe-pravo-1': 'Уголовное право',
  'grazhdanskoe-pravo-1': 'Гражданское право',
  'uchitel-russkogo-yazyka-i-literatury': 'Учитель русского языка и литературы',
  'psihologiya-i-pedagogika-doshkolnogo-obrazovaniya':
    'Психология и педагогика дошкольного образования',
  'teoriya-i-praktika-perevoda': 'Теория и практика перевода',
  'bankovskoe-delo': 'Банковское дело',
  'fizicheskaya-kultura': 'Физическая культура',
  'hudozhnik-animaczii-i-kompyuternoj-grafiki':
    'Художник анимации и компьютерной графики',
  'sistemy-i-sredstva-avtomatizaczii-tehnologicheskih-proczessov':
    'Системы и средства автоматизации технологических процессов',
  'robototehnika-i-iskusstvennyj-intellekt':
    'Робототехника и искусственный интеллект',
  'kompleksnye-sistemy-bezopasnosti': 'Комплексные системы безопасности',
  'kachestvo-i-upravlenie-v-tehnicheskih-sistemah':
    'Качество и управление в технических системах',
  'intellektualnye-sredstva-obrabotki-informaczii':
    'Интеллектуальные средства обработки информации',
  'ekspertiza-i-upravlenie-nedvizhimostyu':
    'Экспертиза и управление недвижимостью',
  'avtomobilnye-dorogi': 'Автомобильные дороги',
  'razrabotka-soprovozhdenie-i-obespechenie-bezopasnosti-informaczionnyh-sistem':
    'Разработка, сопровождение и обеспечение безопасности информационных систем',
  'razrabotka-i-testirovanie-programmnogo-obespecheniya':
    'Разработка и тестирование программного обеспечения',
  'tehnologii-iskusstvennogo-intellekta':
    'Технологии искусственного интеллекта',
  'internet-tehnologii-i-mobilnye-prilozheniya':
    'Интернет технологии и мобильные приложения',
  'psihologiya-i-pedagogika-nachalnogo-obrazovaniya':
    'Психология и педагогика начального образования',
  'pedagogicheskoe-proektirovanie-obrazovatelnogo-kontenta':
    'Педагогическое проектирование образовательного контента',
  'informatika-i-informaczionnye-tehnologii-v-obrazovanii':
    'Информатика и информационные технологии в образовании',
  'ekonomika-gornoj-promyshlennosti': 'Экономика горной промышленности',
  'menedzhment-v-gostinichnom-i-restorannom-biznese':
    'Менеджмент в гостиничном и ресторанном бизнесе',
  'logistika-1': 'Логистика',
  'informaczionnyj-menedzhment': 'Информационный менеджмент',
  'gosudarstvennaya-sluzhba': 'Государственная служба',
  'ekonomiko-pravovoe-obespechenie-ekonomicheskoj-bezopasnosti':
    'Экономико-правовое обеспечение экономической безопасности',
  'detskaya-psihologiya': 'Детская психология',
  nejropsihologiya: 'Нейропсихология',
  'Управление гостиничными и ресторанными предприятиями':
    'upravlenie-gostinichnymi-i-restorannymi-predpriyatiyami',
  'pro-obrazovanie': 'Про образование',
  articles: 'Журнал',
  'journal/articles': 'Журнал',
  '/journal/articles': 'Журнал',
  'kouching-i-psihologicheskoe-konsultirovanie':
    'Коучинг и психологическое консультирование',
  'organizaczionnaya-psihologiya-1': 'Организационная психология',
  'promyshlennoe-i-grazhdanskoe-stroitelstvo':
    'Промышленное и гражданское строительство',
  'vodosnabzhenie-i-vodootvedenie': 'Водоснабжение и водоотведение',
  'teplogazosnabzhenie-i-ventilyacziya': 'Теплогазоснабжение и вентиляция',
  'proizvodstvo-stroitelnyh-materialov-izdelij-i-konstrukczij':
    'Производство строительных материалов, изделий и конструкций',
  'gorodskoe-stroitelstvo-i-hozyajstvo': 'Городское строительство и хозяйство',
  'elektrosnabzhenie-proizvodstvennyh-obektov':
    'Электроснабжение производственных объектов',
  'elektrooborudovanie-i-elektrohozyajstvo-predpriyatij-organizaczij-i-uchrezhdenij':
    'Электрооборудование и электрохозяйство предприятий, организаций и учреждений',
  'elektroenergeticheskie-sistemy-i-seti':
    'Электроэнергетические системы и сети',
  'elektroenergetika-i-elektrotehnika': 'Электроэнергетика и электротехника',
  'bezopasnost-tehnologicheskih-proczessov-i-proizvodstv':
    'Безопасность технологических процессов и производств',
  'bezopasnost-truda': 'Безопасность труда',
  'pozharnaya-bezopasnost': 'Пожарная безопасность',
  'tehnosfernaya-bezopasnost': 'Техносферная безопасность',
  'avtomatizacziya-tehnologicheskih-proczessov-i-proizvodstv-v-teploenergetike-i-teplotehnike':
    'Автоматизация технологических процессов и производств в теплоэнергетике и теплотехнике',
  'promyshlennaya-teploenergetika': 'Промышленная теплоэнергетика',
  'teploenergetika-i-teplotehnika': 'Теплоэнергетика и теплотехника',
  stroitelstvo: 'Строительство',
  'proektirovanie-zdanij-i-sooruzhenij': 'Проектирование зданий и сооружений',
  'magazine-test': 'Тестовый журнал',
  journal: 'Журнал',
  kurs: 'Курсы',
  contact: 'Контакты',
  kursy: 'Курсы',
  bakalavriat: 'Бакалавриат',
  magistratura: 'Магистратура',
  programmy: 'Программы',
  dizajn: 'Дизайн',
  'prikladnaya-informatika': 'Информатика',
  informatika: 'Информатика',
  marketing: 'Маркетинг',
  menedzhment: 'Менеджмент',
  prodazhi: 'Продажи',
  psihologiya: 'Психология',
  ekonomika: 'Экономика',
  yurisprudencziya: 'Юриспруденция',

  'graficheskij-dizajner': 'Графический дизайнер',
  'klinicheskaya-psihologiya-2': 'Клиническая психология',
  'psiholog-konsultant': 'Психолог-консультант',
  'biznes-analitika': 'Бизнес-аналитика',
  'arbitrazhnoe-antikrizisnoe-upravlenie':
    'Арбитражное антикризисное управление',
  predprinimatelstvo: 'Предпринимательство',
  'menedzhment-v-energetike': 'Менеджмент в энергетике',
  'dogovornoe-pravo': 'Договорное право',
  'pravovoe-soprovozhdenie-soczialnogo-obespecheniya-grazhdan-rf':
    'Правовое сопровождение социального обеспечения граждан РФ',
  'klinicheskaya-psihologiya-1': 'Клиническая психология',
  'trudovoe-pravo': 'Трудовое право',
  'landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo':
    'Ландшафтный дизайн и садово-парковое строительство',
  'korporativnaya-psihologiya-1': 'Корпоративная психология',
  'menedzhment-v-neftegazovom-komplekse-1':
    'Менеджмент в нефтегазовом комплексе',
  'upravlenie-proektami-3': 'Управление проектами',
  'assistent-rukovoditelya': 'Ассистент руководителя',
  'psihologiya-semejnyh-otnoshenij': 'Психология семейных отношений',
  rieltor: 'Риелтор',
  'mediczinskoe-pravo': 'Медицинское право',
  'hr-menedzher-s-nulya': 'HR-менеджер (с нуля)',
  'semejnaya-psihologiya': 'Семейная психология',
  'finansovyj-menedzhment': 'Финансовый менеджмент',
  'direktor-po-marketingu': 'Директор по маркетингу',
  'kouching-individualnoe-psihologicheskoe-konsultirovanie':
    'Коучинг - индивидуальное психологическое консультирование',
  'dizajner-interera': 'Дизайнер интерьера',
  'kognitivno-povedencheskaya-psihoterapiya':
    'Когнитивно-поведенческая психотерапия',
  'prikladnaya-informatika-i-matematika': 'Прикладная информатика и математика',
  'prakticheskaya-psihologiya-s-dop-kvalifikacziej-psiholog-psihoterapevt':
    'Практическая психология с доп. квалификацией Психолог-психотерапевт',
  'yuridicheskaya-psihologiya': 'Юридическая психология',
  'buhgalterskij-uchet-analiz-i-audit': 'Бухгалтерский учет, анализ и аудит',
  'soczialnaya-psihologiya-1': 'Социальная психология',
  'mirovaya-ekonomika': 'Мировая экономика',
  'psihologiya-1': 'Психология',
  'ekonomika-i-upravlenie-v-uchrezhdeniyah-zdravoohraneniya':
    'Экономика и управление в учреждениях здравоохранения',
  'mezhdunarodnye-finansy': 'Международные финансы',
  'psihologicheskoe-konsultirovanie-2': 'Психологическое консультирование',
  'internet-marketing': 'Интернет-маркетинг',
  'intellektualnye-informaczionnye-sistemy-i-tehnologii':
    'Интеллектуальные информационные системы и технологии',
  'ekonomika-v-soczialnoj-sfere': 'Экономика в социальной сфере',
  'uchet-i-finansovyj-analiz': 'Учет и финансовый анализ',
  'regionalnaya-ekonomika': 'Региональная экономика',
  'razrabotka-i-dizajn-veb-prilozhenij': 'Разработка и дизайн веб-приложений',
  'finansovyj-kontrol-i-ekonomicheskij-analiz':
    'Финансовый контроль и экономический анализ',
  'menedzher-prodazham': 'Менеджер по продажам',
  'dengi-banki-finansovye-rynki': 'Деньги, банки, финансовые рынки',
  'kadrovyj-menedzhment-1': 'Кадровый менеджмент',
  'czifrovaya-transformacziya-informaczionnyh-sistem':
    'Цифровая трансформация информационных систем',
  konfliktologiya: 'Конфликтология',
  nutricziolog: 'Нутрициолог',
  'ekonomika-i-ekonomicheskaya-bezopasnost-predpriyatiya':
    'Экономика и экономическая безопасность предприятия',
  'rukovoditel-otdela-prodazh': 'Руководитель отдела продаж',
  'antikrizisnoe-upravlenie-1': 'Антикризисное управление',
  'yuridicheskie-aspekty-upravlenii-v-sfere-zh-kh':
    'Юридические аспекты управлении в сфере ЖКХ',
  'prakticheskaya-psihologiya-s-dop-kvalifikacziej-pedagog-psiholog':
    'Практическая психология с доп. квалификацией педагог-психолог',
  'organizaczionnaya-psihologiya': 'Организационная психология',
  zoopsiholog: 'Зоопсихолог',
  kommercziya: 'Коммерция',
  'vnutrennij-audit': 'Внутренний аудит',
  'product-menedzher': 'Product менеджер',
  'menedzhment-v-stroitelstve': 'Менеджмент в строительстве',
  'upravlenie-malym-biznesom': 'Управление малым бизнесом',
  'menedzhment-v-neftegazovom-komplekse': 'Менеджмент в нефтегазовом комплексе',
  'menedzhment-v-obrazovanii': 'Менеджмент в образовании',
  'logisticheskij-menedzhment': 'Логистический менеджмент',
  'upravlenie-gosudarstvennym-i-municzipalnym-sektorom':
    'Управление государственным и муниципальным сектором',
  'ekonomika-i-upravlenie-na-predpriyatii':
    'Экономика и управление на предприятии',
  pravovedenie: 'Правоведение',
  'menedzhment-v-zdravoohranenii': 'Менеджмент в здравоохранении',
  'upravlenie-proektami-1': 'Управление проектами',
  'ekonomika-predpriyatij-i-organizaczij':
    'Экономика предприятий и организаций',
  'menedzhment-v-stroitelstve-1': 'Менеджмент в строительстве',
  'sportivnyj-menedzhment-1': 'Спортивный менеджмент',
  'prikladnaya-informatika-v-ekonomike': 'Прикладная информатика в экономике',
  'suggestivnaya-psihologiya-gipnoz-v-psihologicheskom-konsultirovanii':
    'Суггестивная психология. Гипноз в психологическом консультировании',
  'czifrovaya-ekonomika': 'Цифровая экономика',
  'informaczionno-kommunikaczionnye-tehnologii-i-analitika-dannyh':
    'Информационно-коммуникационные технологии и аналитика данных',
  'kompyuternaya-grafika': 'Компьютерная графика',
  'ugolovnoe-pravo': 'Уголовное право',
  'geshtalt-terapiya': 'Гештальт-терапия',
  'prakticheskaya-psihologiya': 'Практическая психология',
  'finansy-i-kredit': 'Финансы и кредит',
  'nalogi-i-nalogooblozhenie-1': 'Налоги и налогообложение',
  'vychislitelnye-mashiny-kompleksy-sistemy-i-seti':
    'Вычислительные машины, комплексы, системы и сети',
  inzhiniring: 'Инжиниринг',
  strahovanie: 'Страхование',
  'logoped-defektolog': 'Логопед-дефектолог',
  'logoped-defektolog-1': 'Логопед-дефектолог',
  'ekonomika-i-statistika': 'Экономика и статистика',
  'razrabotka-kompyuternyh-igr-i-multimedijnyh-prilozhenij':
    'Разработка компьютерных игр и мультимедийных приложений',
  psihoanaliz: 'Психоанализ',
  'art-terapiya': 'Арт-терапия',
  'psiholog-pedagog-1': 'Психолог-педагог',
  'psihoanaliz-i-psihologicheskaya-psihoterapiya':
    'Психоанализ и психологическая психотерапия',
  patentovedenie: 'Патентоведение',
  'klinicheskie-osnovy-psihologicheskogo-zdorovya-cheloveka':
    'Клинические основы психологического здоровья человека',
  'proektnyj-menedzhment': 'Проектный менеджмент',
  advokatura: 'Адвокатура',
  'psihologicheskoe-konsultirovanie-1': 'Психологическое консультирование',
  'psihologiya-upravleniya': 'Психология управления',
  'bim-tehnologii': 'BIM-технологии',
  'oczenka-biznesa-i-riskov': 'Оценка бизнеса и рисков',
  'sdelki-s-nedvizhimostyu': 'Сделки с недвижимостью',
  mediacziya: 'Медиация',
  'upravlenie-kachestvom': 'Управление качеством',
  'finansovyj-menedzhment-1': 'Финансовый менеджмент',
  'menedzhment-v-organizaczii': 'Менеджмент в организации',
  'krizisnyj-psiholog-1': 'Кризисный психолог',
  'menedzhment-organizaczii-v-zdravoohranii':
    'Менеджмент организации в здравоохрании',
  'grazhdanskoe-pravo': 'Гражданское право',
  'informaczionnoe-obespechenie-biznes-proczessov':
    'Информационное обеспечение бизнес-процессов',
  'informaczionnoe-obespechenie-avtomatizirovannyh-sistem':
    'Информационное обеспечение автоматизированных систем',
  'korrekczionnaya-psihologiya-s-osnovami-logopedii':
    'Коррекционная психология с основами логопедии',
  'pedagog-psiholog-1': 'Педагог-психолог',
  'geoinformaczionnye-tehnologii-i-3-d-modelirovanie':
    'Геоинформационные технологии и 3D-моделирование',
  'soczialnaya-psihologiya': 'Социальная психология',
  'kouching-psihologicheskoe-biznes-konsultirovanie':
    'Коучинг. Психологическое бизнес-консультирование',
  'seksologiya-v-psihologicheskom-konsultirovanii':
    'Сексология в психологическом консультировании',
  'upravlencheskij-i-finansovyj-uchet': 'Управленческий и финансовый учет',
  'upravlenie-chelovecheskimi-resursami': 'Управление человеческими ресурсами',

  'korporativnye-finansy': 'Корпоративные финансы',
  'proforientaczionnaya-rabota-v-obrazovatelnom-uchrezhdenii':
    'Профориентационная работа в образовательном учреждении',
  'menedzhment-v-mashinostroenii': 'Менеджмент в машиностроении',
  'upravlenie-personalom': 'Управление персоналом',
  'upravlenie-personalom-2': 'Управление персоналом',
  'ekonomika-i-logistika-predpriyatiya': 'Экономика и логистика предприятия',
  'dokumentovedenie-i-arhivovedenie': 'Документоведение и архивоведение',
  'organizaczionnyj-menedzhment': 'Организационный менеджмент',
  'upravlenie-vneshneekonomicheskoj-deyatelnostyu':
    'Управление внешнеэкономической деятельностью',
  'graficheskij-dizajn-1': 'Графический дизайн',
  'internet-marketing-1': 'Интернет-маркетинг',
  'marketing-1': 'Маркетинг',
  'marketing-i-upravlenie-brendami': 'Маркетинг и управление брендами',
  'marketing-v-kommercheskoj-deyatelnosti':
    'Маркетинг в коммерческой деятельности',
  'dengi-banki-finansovye-rynki-1': 'Деньги, банки, финансовые рынки',
  'menedzhment-gostinichnyh-i-restorannyh-predpriyatij':
    'Менеджмент гостиничных и ресторанных предприятий',
  'strategicheskij-menedzhment': 'Стратегический менеджмент',
  'upravlenie-prodazhami': 'Управление продажами',
  'upravlenie-zh-kh': 'Управление ЖКХ',
  'menedzhment-v-turizme': 'Менеджмент в туризме',
  'upravlenie-bankovskoj-i-informaczionnoj-bezopasnostyu':
    'Управление банковской и информационной безопасностью',
  'proizvodstvennyj-menedzhment': 'Производственный менеджмент',
  'buhgalterskij-uchet-i-finansovyj-menedzhment-1':
    'Бухгалтерский учет и финансовый менеджмент',
  'antikrizisnyj-menedzhment': 'Антикризисный менеджмент',
  'upravlenie-na-transporte': 'Управление на транспорте',
  'menedzhment-v-smi-i-reklame': 'Менеджмент в СМИ и рекламе',
  'upravlenie-tehnologicheskimi-innovacziyami':
    'Управление технологическими инновациями',
  'psihosomatika-i-telesno-orientirovannaya-terapiya':
    'Психосоматика и телесно-ориентированная терапия',
  'yuridicheskie-aspekty-upravleniya-v-sfere-zh-kh-1':
    'Юридические аспекты управления в сфере ЖКХ',
  'menedzhment-organizaczii-v-zdravoohranenii':
    'Менеджмент организации в здравоохранении',
  college: 'Колледж',
  'gruppa-vyhodnogo-dnya': 'Группа выходного дня',
  'montazh-naladka-i-ekspluatacziya-elektrooborudovaniya-promyshlennyh-i-grazhdanskih-sooruzhenij':
    'Монтаж, наладка и эксплуатация электрооборудования промышленных и гражданских сооружений',
  'operator-dispetcherskoj-proizvodstvenno-dispetcherskoj-sluzhby':
    'Оператор диспетчерской (производственно-диспетчерской) службы',
  'operator-informaczionnyh-sistem-i-resursov':
    'Оператор информационных систем и ресурсов',
  'stroitelstvo-i-ekspluatacziya-zdanij-i-sooruzhenij':
    'Строительство и эксплуатация зданий и сооружений',
  'torgovoe-delo': 'Торговое дело',
  'chertezhnik-konstruktor': 'Чертежник-конструктор',
  'ekonomika-i-buhgalterskij-uchet-po-otraslyam':
    'Экономика и бухгалтерский учет (по отраслям)',
  'bankovskoe-delo-1': 'Банковское дело',
  'informaczionnye-sistemy-i-programmirovanie':
    'Информационные системы и программирование',
  // journal журнал
  '6-tipov-modulnyh-setok-v-ux-ui-dizajne-i-dlya-chego-oni-nuzhny':
    '6 типов модульных сеток в ux / ui дизайне и для чего они нужны',
  'cjm-ili-customer-journey-map-kak-sostavit-i-oczenit-effektivnost':
    'CJM или Customer Journey Map: как составить и оценить эффективность',
  'it-speczialnosti-samye-vostrebovannye-napravleniya':
    'IT-специальности — самые востребованные направления',
  'it-sfera-11-populyarnyh-professij': 'IT-сфера: 11 популярных профессий',
  'man-in-the-middle-chto-eto-takoe': 'Man in the middle — что это такое?',
  'kem-ty-stanesh-kogda-vyrastesh-kuda-postupit-posle-11-klassa':
    '«Кем ты станешь, когда вырастешь»: куда поступить после 11 класса',
  'adekvatnaya-samooczenka-luchshij-sposob-zhit-v-garmonii-s-soboj':
    'Адекватная самооценка — лучший способ жить в гармонии с собой',
  'ajti-speczialisty-kto-eto-i-chem-oni-zanimayutsya':
    'Айти-специалисты — кто это и чем они занимаются?',
  'boremsya-s-emoczionalnym-vygoraniem-kak-obnaruzhit-i-pobedit-sostoyanie':
    'Боремся с эмоциональным выгоранием.Как обнаружить и победить состояние',
  'buhgalter-ekonomist-plyusy-i-minusy-professii-perspektivy-trudoustrojstva':
    'Бухгалтер-экономист: плюсы и минусы профессии, перспективы трудоустройства',
  'vidy-graficheskogo-dizajna-osobennosti-i-primenenie':
    'Виды графического дизайна — особенности и применение',
  'vidy-iskusstvennogo-intellekta-ih-osobennosti-i-primenenie':
    'Виды искусственного интеллекта — их особенности и применение',
  'vidy-samooczenki-kak-povliyat-na-slozhivshiesya-predstavleniya-o-sebe':
    'Виды самооценки: как повлиять на сложившиеся представления о себе ?',
  'vse-ob-iskusstvennom-intellekte-s-pervyh-shagov-do-vnedreniya-nejrosetej-v-proizvodstvennye-i-biznes-proczessy':
    'Все об искусственном интеллекте с первых шагов до внедрения нейросетей в производственные и бизнес-процессы',
  'vysshee-obrazovanie-bez-ege-eto-vozmozhno':
    'Высшее образование без ЕГЭ - это возможно ?',
  'gosudarstvennye-finansy-chto-eto-i-kak-oni-vliyayut-na-ekonomiku':
    'Государственные финансы: что это и как они влияют на экономику',
  'graficheskij-dizajn-chto-eto-takoe-i-v-kakih-sferah-trebuetsya':
    'Графический дизайн: что это такое и в каких сферах требуется',
  'dizajn-konczepcziya-i-vizualnye-metafory-gde-nahodit-idei-i-kak-rabotat-nad-proektom':
    'Дизайн-концепция и визуальные метафоры: где находить идеи и как работать над проектом',
  'zaochnoe-vysshee-obrazovanie-bez-ege-v-kakih-sluchayah-vozmozhno-postupit':
    'Заочное высшее образование без ЕГЭ: в каких случаях возможно поступить ? ',
  'iskusstvennyj-intellekt-v-zhizni-cheloveka':
    'Искусственный интеллект в жизни человека',
  'kak-razrabotat-iskusstvennyj-intellekt-poshagovoe-rukovodstvo':
    'Как разработать искусственный интеллект: пошаговое руководство',
  'kak-spravitsya-s-emoczionalnym-vygoraniem-5-sovetov-psihologa':
    'Как справиться с эмоциональным выгоранием: 5 советов психолога',
  'kak-stat-it-speczialistom-sekrety-uspeha-ot-professionalov':
    'Как стать IT специалистом: секреты успеха от профессионалов',
  'kakoj-yazyk-programmirovaniya-vybrat-novichku-vidy-yazykov-i-harakteristiki':
    'Какой язык программирования выбрать новичку — виды языков и характеристики',
  'kakuyu-professiyu-vybrat-posle-11-klassa':
    'Какую профессию выбрать после 11 класса',
  'knigi-po-programmirovaniyu-s-nulya-prakticheskoe-rukovodstvo-dlya-nachinayushhih':
    'Книги по программированию с нуля: Практическое руководство для начинающих',
  'knigi-po-programmirovaniyu-podborka-dlya-teh-kto-hochet-prokachat-svoi-navyki':
    'Книги по программированию: подборка для тех, кто хочет прокачать свои навыки',
  'knigi-po-razvitiyu-emoczionalnogo-intellekta-instrukczii-kak-upravlyat-emocziyami':
    'Книги по развитию эмоционального интеллекта — инструкции, как управлять эмоциями',
  'knigi-po-ekonomike-dlya-nachinayushhih-nashi-rekomendaczii':
    'Книги по экономике для начинающих: наши рекомендации',
  'komanda-proekta-rol-kazhdogo-chlena-v-uspeshnom-reshenii-zadach':
    'Команда проекта — роль каждого члена в успешном решении задач',
  'kompozicziya-v-dizajne-kak-sozdat-idealnyj-sajt':
    'Композиция в дизайне — как создать идеальный сайт',
  'kompyuternyj-dizajn-ponyatiya-i-osobennosti':
    'Компьютерный дизайн: понятия и особенности',
  'konczeptualnyj-dizajn-i-ego-znachenie-dlya-proekta':
    'Концептуальный дизайн и его значение для проекта',
  'krizis-v-psihologii-eto-tonkosti-i-nyuansy':
    'Кризис в психологии - это… Тонкости и нюансы',
  'krizis-lichnosti-chto-eto-takoe-i-kak-s-nim-borotsya':
    'Кризис личности: что это такое и как с ним бороться ?',
  'marketingovoe-issledovanie-rynka-zachem-nuzhno-biznesu-i-kak-ego-provesti':
    'Маркетинговое исследование рынка: зачем нужно бизнесу и как его провести',
  'marketingovoe-issledovanie-vidy-czeli-i-zadachi':
    'Маркетинговое исследование.Виды, цели и задачи',
  'marketingovye-issledovaniya-testiruem-gipotezy-pri-vedenii-biznesa':
    'Маркетинговые исследования: тестируем гипотезы при ведении бизнеса ',
  'marketingovyj-analiz-vidy-i-etapy-issledovaniya':
    'Маркетинговый анализ — виды и этапы исследования',
  'metody-iskusstvennogo-intellekta-osobennosti-kazhdogo-podhoda':
    'Методы искусственного интеллекта — особенности каждого подхода',
  'minimalnye-bally-po-ege-dlya-postupleniya-chto-delat-esli-ne-preodolel-porog-dlya-vuza':
    'Минимальные баллы по ЕГЭ для поступления: что делать, если не преодолел порог для вуза',
  'mozhno-li-postupit-v-vuz-bez-ege-vot-v-chyom-vopros':
    'Можно ли поступить в вуз без ЕГЭ ? Вот в чём вопрос!',
  'oblasti-primeneniya-ii-ot-tekstov-do-selskogo-hozyajstva':
    'Области применения ИИ: от текстов до сельского хозяйства',
  'obrazovanie-it-gde-poluchit-i-kak-vybrat-uchebnoe-zavedenie':
    'Образование IT: где получить и как выбрать учебное заведение',
  'osnovy-graficheskogo-dizajna-vizualnaya-kommunikacziya-brenda-s-auditoriej':
    'Основы графического дизайна.Визуальная коммуникация бренда с аудиторией',
  'osobennosti-postupleniya-v-vuz-posle-kolledzha-bez-ege':
    'Особенности поступления в Вуз после колледжа без ЕГЭ',
  'pedagogicheskoe-issledovanie-kakie-zadachi-reshaet':
    'Педагогическое исследование — какие задачи решает?',
  'plyusy-i-minusy-iskusstvennogo-intellekta-vo-vseh-sferah-zhizni-cheloveka':
    'Плюсы и минусы искусственного интеллекта во всех сферах жизни человека',
  'postuplenie-v-vuz-posle-kolledzha-chto-nuzhno-znat-abiturientu-2':
    'Поступление в ВУЗ после колледжа: что нужно знать абитуриенту?',
  'pravila-kompoziczii-sozdaem-pervoe-vpechatlenie-ot-produkta-cherez-dizajn':
    'Правила композиции. Создаем первое впечатление от продукта через дизайн',
  'princzipy-marketingovyh-issledovanij-sut-ih-provedeniya':
    'Принципы маркетинговых исследований — суть их проведения',
  'programma-marketingovogo-issledovaniya-osobennosti-i-czel':
    'Программа маркетингового исследования — особенности и цель',
  'professionalnoe-vygoranie-na-rabote-chto-eto-i-kak-vyjti-iz-etogo-sostoyaniya':
    'Профессиональное выгорание на работе — что это и как выйти из этого состояния?',
  'proczess-strategicheskogo-planirovaniya-dostoinstva-etapy-instrumenty-analiza':
    'Процесс стратегического планирования: достоинства, этапы, инструменты анализа',
  'psiholog-vostrebovannaya-professiya-2023-plyusy-i-minusy-speczialnosti-kuda-pojti-uchitsya':
    'Психолог — востребованная профессия 2024: плюсы и минусы специальности, куда пойти учиться',
  'rezyume-programmista-kak-pravilno-sostavit-i-chto-pisat':
    'Резюме программиста: как правильно составить и что писать',
  'rol-finansov-v-ekonomike-i-vypolnyaemye-imi-funkczii':
    'Роль финансов в экономике и выполняемые ими функции',
  'samooczenka-kak-formiruetsya-i-na-chto-vliyaet':
    'Самооценка: как формируется и на что влияет ? ',
  'sindrom-otlozhennoj-zhizni-kak-raspoznat-i-izbavitsya-ot-nego':
    'Синдром отложенной жизни: как распознать и избавиться от него',
  'stili-graficheskogo-dizajna-kakie-ispolzuyut-segodnya-v-proektah':
    'Стили графического дизайна: какие используют сегодня в проектах',
  'strategiya-v-menedzhmente-eto-osnova-uspeshnogo-razvitiya-kompanii':
    'Стратегия в менеджменте — это основа успешного развития компании',
  'strategiya-razvitiya-kompanii-kak-sostavit-effektivnyj-plan-dlya-biznesa':
    'Стратегия развития компании: как составить эффективный план для бизнеса',
  'sut-generativnogo-tvorchestva-2': 'Суть генеративного творчества',
  'testovoe-zadanie-dlya-dizajnerov-zachem-nuzhno-i-kak-ego-vypolnyat':
    'Тестовое задание длядизайнеров: зачем нужно и как его выполнять',
  'top-10-samyh-legkih-yazykov-programmirovaniya-dlya-sozdaniya-veb-sajtov':
    'Топ-10 самых легких языков программирования для создания веб - сайтов',
  'finansovye-professii-vostrebovannye-v-mire':
    'Финансовые профессии — востребованные в мире',
  'finansovyj-analitik-chem-zanimaetsya-skolko-zarabatyvaet-i-kak-osvoit-professiyu':
    'Финансовый аналитик: чем занимается, сколько зарабатывает и как освоить профессию',
  'czel-marketingovogo-issledovaniya-osnova-uspeshnoj-strategii-prodvizheniya-na-rynke':
    'Цель маркетингового исследования: основа успешной стратегии продвижения на рынке',
  'czifrovaya-ekonomika-opredelenie-ponyatiya-osobennosti-i-kak-ona-vliyaet-na-biznes':
    'Цифровая экономика: определение понятия, особенности и как она влияет на бизнес',
  'chto-delat-esli-ne-znaesh-kuda-postupat-sovety-speczialistov':
    'Что делать если не знаешь куда поступать - советы специалистов',
  'chto-takoe-sindrom-vygoraniya-i-kak-s-nim-borotsya':
    'Что такое синдром выгорания и как с ним бороться',
  'chto-takoe-sindrom-otlozhennoj-zhizni-ili-kak-nauchitsya-zhit-segodnyashnim-dnem':
    'Что такое синдром отложенной жизни или как научиться жить сегодняшним днем',
  'chto-takoe-finansy-peremeshhenie-deneg-eto-kratko':
    'Что такое финансы — перемещение денег, это кратко',
  'ekonomist-analitik-obyazannosti-i-navyki-dlya-uspeshnoj-karery':
    'Экономист-аналитик: обязанности и навыки для успешной карьеры',
  'ekonomist-menedzher-plyusy-i-minusy-professii':
    'Экономист-менеджер: плюсы и минусы профессии',
  'ekonomist-professiya-s-vysokoj-vostrebovannostyu-i-perspektivami':
    'Экономист: профессия с высокой востребованностью и перспективами',
  'ekonomicheskoe-obrazovanie-klyuch-k-uspeshnoj-karere-v-finansovoj-sfere':
    'Экономическое образование: ключ к успешной карьере в финансовой сфере',
  'emoczionalnyj-intellekt-eto-vazhno-dlya-razvitiya-lyudej-i-otnoshenij':
    'Эмоциональный интеллект – это важно для развития людей и отношений',
  'yuzabiliti-testirovanie-vidy-i-osobennosti-etogo-issledovaniya':
    'Юзабилити-тестирование — виды и особенности этого исследования',
  'yurist-eto-professional-stoyashhij-na-zashhite-prav-cheloveka':
    'Юрист — это профессионал, стоящий на защите прав человека',
  'speczialist-po-mehatronike-i-robototehnike':
    'Специалист по мехатронике и робототехнике',
  'czifrovye-media-v-kibersporte': 'Цифровые медиа в киберспорте',
  'predprinimatelstvo-1': 'Предпринимательство',
  'razrabotchik-igr': 'Разработчик игр',
  'vospitatel-detej-doshkolnogo-vozrasta':
    'Воспитатель детей дошкольного возраста',
  'uchitel-nachalnyh-klassov': 'Учитель начальных классов',
  'speczialist-bankovskogo-dela': 'Специалист банковского дела',
  finansist: 'Финансист',
  'internet-marketing-2': 'Интернет-маркетинг',
  'speczialist-po-turizmu-i-gostepriimstvu':
    'Специалист по туризму и гостеприимству',
  'buhgalter-1': 'Бухгалтер',
  yurist: 'Юрист',
  'speczialist-po-informaczionnym-sistemam':
    'Специалист по информационным системам',
  programmist: 'Программист',
  'prohodnoj-ball-ege-chem-on-otlichaetsya-ot-minimalnogo':
    'Проходной балл ЕГЭ, чем он отличается от минимального?',
  'razvitie-komandy-proekta-kak-sozdat-silnuyu-i-splochennuyu-gruppu':
    'Развитие команды проекта: как создать сильную и сплоченную группу',
  'vozrastnaya-psihologiya-kak-vliyaet-na-prinyatie-vazhnyh-zhiznennyh-reshenij':
    'Возрастная психология: как влияет на принятие важных жизненных решений',
  'chto-takoe-mediacziya-princzipy-i-pravila-provedeniya':
    'Что такое медиация — принципы и правила проведения',
  'wbs-proekta-moshhnyj-instrument-dlya-dostizheniya-czelej-v-proektnoj-deyatelnosti':
    'WBS проекта: мощный инструмент для достижения целей в проектной деятельности',
  'pedagogicheskie-issledovaniya-kakie-metody-pomogayut-dostich-uspeha-v-obuchenii':
    'Педагогические исследования: какие методы помогают достичь успеха в обучении?',
  mba: 'МБА',
  'menedzhment-organizaczii-v-zdravoohranenii-1':
    'Менеджмент организации в здравоохранении',
  'upravlenie-gostinichnymi-i-restorannymi-predpriyatiyami-1':
    'Управление гостиничными и ресторанными предприятиями',
  'antikrizisnoe-upravlenie-2': 'Антикризисное управление'
}
